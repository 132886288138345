(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/get-outcome-labels.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/get-outcome-labels.js');
"use strict";


const {
  productIds
} = svs.utils.products;
const getOutcomeLabels = (minValue, maxValue, productId) => {
  const outcomeArray = new Array(maxValue - minValue + 1).fill(false);
  let outcomeLabels = outcomeArray.map((_, i) => ({
    text: "".concat(minValue + i),
    sign: minValue + i,
    ariaLabel: "".concat(minValue + i)
  }));
  if (productId !== productIds.MATCHEN) {
    outcomeLabels = outcomeLabels.slice(0, -1).concat({
      text: "".concat(outcomeLabels.slice(-1)[0].text, "+"),
      ariaLabel: "".concat(outcomeLabels.slice(-1)[0].text, " eller fler"),
      sign: parseInt(outcomeLabels.slice(-1)[0].text, 10)
    });
  }
  return outcomeLabels;
};
setGlobal('svs.components.tipsen.hooks.getOutcomeLabels', getOutcomeLabels);

 })(window);